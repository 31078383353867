import {createContext} from '@lit-labs/context';
import {FeatureFlagKey} from '@v2/lib/feature_flags/FeatureFlag';
import {Nullable} from '@v2/types/general';
import {User} from '@v2/types/user';

export type SessionContextData = {
  user: () => Nullable<User>;
  enabled: (...keys: FeatureFlagKey[]) => boolean;
};

export const SessionContext =
  createContext<SessionContextData>('session-context');
